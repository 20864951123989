@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
    @apply bg-black text-white;
}